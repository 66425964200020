import React from "react";
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { withPreview } from 'gatsby-source-prismic'


import SEO from 'components/Global/SEO';

const Sitemap = ({data}) => {

  console.log(data);

  return (
    <div>
      <SEO title="Sitemap" description="Sitemap pagina voor beweegroutehoorn.nl" />
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6">
            <h1 className="text-primary">Sitemap<span className="text-secondary">.</span></h1>
            
          </div>
        </div>

          <div className="row">
            <div className="col-md-8">
              <ul>
                <li><Link to="/">Homepage</Link></li>
                <li>
                  <Link to="/routes">Routes</Link>
                  <ul>
                    {data.routes.edges.map((item) => (
                      <li key={item.node.uid}>
                        <Link to={`/route${item.node.url}`}>
                          {item.node.data.titel.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li><Link to="/oefeningen">Oefeningen</Link></li>
                <li><Link to="/organisaties">Organisaties</Link></li>
                <li><Link to="/sparen">Sparen</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                {data.pages.edges.map((item) => {
                  if(item.node.uid === "oefeningen" || 
                  item.node.uid === "sparen" ||
                  item.node.uid === "routes") {
                    return null;
                  }

                  return (
                    <li key={item.node.uid}>
                      <Link to={`/${item.node.uid}`}>
                        {item.node.data.titel.text}
                      </Link>
                    </li>
                  )
                }
                )}
              </ul>
            </div>
          </div>

      </div>
    </div>
  );
}

export const query = graphql`
  query {
    routes: allPrismicRoute {
      edges {
        node {
          url
          uid
          data {
            titel {
              text
            }
          }
        }
      }
    },
    pages: allPrismicTekstpagina {
      edges {
        node {
          url
          uid
          data {
            titel {
              text
            }
          }
        }
      }
    }
  }
`

export default withPreview(Sitemap);